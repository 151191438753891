import { Col, Flex, Layout, Menu, MenuProps, Row, Typography } from "antd";
import Container from "components/Common/Container";
import Logo from "components/Common/Logo";
import NavLink from "components/Common/NavLink";
import TImage from "components/Common/TImage";
import BurgermenuExternal from "components/Layouts/Public/PublicNav/BurgermenuExternal";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import { RiProductHuntLine } from "react-icons/ri";
import { useCategoriesUseCase } from "services/ApiHooks";
import { Analytics } from "utils/Analytics/AnalyticsHelper";
import config from "utils/config";

const SignupNav = dynamic(() => import("./SignupNav"), {
  ssr: false,
});

const { Header } = Layout;
const { Text, Paragraph } = Typography;

interface IPublicNavProps {
  page: "home" | "common" | "category";
  theme?: "light" | "dark" | "custom" | undefined;
  customTheme?: string;
}

/* Feature detection */
let passiveIfSupported: any = { passive: false };

const debounce = (callback, wait) => {
  let timeoutId: any = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

const PublicNav: React.FC<IPublicNavProps> = ({
  page,
  theme = "light",
  customTheme = "#fff",
}) => {
  const router = useRouter();
  const { categories, isLoading } = useCategoriesUseCase(0);

  const onMenuClick = (e) => {
    console.log("Menu Click: ", e);
  };

  const PublicMenuItems: MenuProps["items"] = [
    {
      label: (
        <Text
          className={
            theme == "dark"
              ? "public-menu-link dark-menu-link"
              : "public-menu-link"
          }
          style={{ alignItems: "center", display: "flex" }}
        >
          For Creators <FaChevronDown style={{ marginLeft: 8 }} />
        </Text>
      ),
      key: "use-cases",
      popupClassName: "public-submenu",
      onTitleClick: onMenuClick,
      children:
        !isLoading &&
        categories?.map((category) => {
          return {
            label: category?.name,
            key: category?.slug,
            icon: category?.icon ? (
              <TImage
                src={category?.icon}
                alt={category?.name || "Icon"}
                width={30}
                height={30}
              />
            ) : (
              <RiProductHuntLine />
            ),
          };
        }),
    },
  ];

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;
    const elementPos = document.getElementById("homepage-scroll");
    const navElem = document.getElementById("nav");
    if (elementPos && currentScrollPos > elementPos?.offsetTop) {
      navElem?.classList?.remove("flat");
    } else {
      navElem?.classList?.add("flat");
    }
  }, 10);

  const onClick: MenuProps["onClick"] = (e) => {
    e?.key === "pricing" || e?.key === "about"
      ? router.push(e?.key)
      : router.push(`/for/${e?.key}`);
  };

  useEffect(() => {
    if (page === "home" || page === "category") {
      try {
        window.addEventListener(
          "scroll",
          () => {},
          Object.defineProperty({}, "passive", {
            // eslint-disable-next-line getter-return
            get() {
              passiveIfSupported = { passive: true };
            },
          })
        );
      } catch (err) {
        console.log("error: ", err);
      }

      window.addEventListener("scroll", handleScroll, passiveIfSupported);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <Container
      type="fluid"
      className={`public-nav ${
        page === "home" ? "flat" : theme == "dark" ? "back-dark" : ""
      } ${page === "category" ? "category-bg flat" : ""}`}
      style={{
        backgroundColor: customTheme ? customTheme : "#fff",
      }}
      id="nav"
    >
      <Container>
        {/* Desktop */}
        <Row justify="center" align="middle">
          <Col xs={{ span: 0, offset: 0 }} md={{ span: 18, offset: 0 }}>
            <Header className="public-nav-header">
              <Logo width={200} height={37} theme={theme} />
              <Menu
                className="public-menu"
                items={PublicMenuItems}
                mode="horizontal"
                onClick={onClick}
                triggerSubMenuAction="click"
              />
              <ul className="public-menu-links">
                <li className="links">
                  <NavLink
                    href={`/pricing`}
                    className={
                      theme == "dark"
                        ? "public-menu-link dark-menu-link"
                        : "public-menu-link"
                    }
                  >
                    Pricing
                  </NavLink>
                </li>
                <li className="links">
                  <a
                    href={`${config.BASE_URL}#earnings`}
                    onClick={() =>
                      Analytics.track("NavClicked", {
                        Type: "Earnings Calculator",
                        Device: "Desktop",
                      })
                    }
                    className={
                      theme == "dark"
                        ? "public-menu-link dark-menu-link"
                        : "public-menu-link"
                    }
                  >
                    Earnings calculator
                  </a>
                </li>
              </ul>
            </Header>
          </Col>
          <Col
            xs={{ span: 0, offset: 0 }}
            md={{ span: 6, offset: 0 }}
            className="nav-links"
          >
            <Flex gap={15} align="center">
              {/* <Button
                icon={<FaSearch />}
                className={"ai-search-button"}
                onClick={() => {
                  router.push(`${config.BASE_URL}#ai-search`);
                  Analytics.track("NavClicked", {
                    Type: "AI Search",
                    Device: "Desktop",
                  });
                }}
              >
                AI Search{" "}
                <LottieComponent
                  src={eyelottie}
                  play
                  style={{ margin: "0 auto", width: "60px" }}
                  loop
                />
              </Button> */}
              <SignupNav />
            </Flex>
          </Col>
        </Row>

        {/* Mobile */}
        <Row justify="center" align="middle">
          <Col xs={{ span: 22, offset: 0 }} md={0}>
            <Row justify="space-between" align="middle">
              <Col xs={{ span: 6, offset: 0 }} md={0}>
                <Logo width={120} theme={theme} logo />
              </Col>
              <Col
                xs={{ span: 14, offset: 0 }}
                md={0}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <BurgermenuExternal theme={theme} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default PublicNav;
