import { Col, Flex, Row, Typography } from "antd";
import Container from "components/Common/Container";
import { useState } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import TImage from "../../../Common/TImage";
import styles from "./possibilities.module.scss";

const { Paragraph } = Typography;
const PossibilitiesSection = () => {
  const [expertImageSlider, setExpertImageSlider] = useState<any>({});

  const reviews = [
    {
      id: 1,
      title:
        "I earned over $1400 in just 75 minutes by turning on pay what you want for my webinar. It was pure magic!",
      desc: "Niharika helps creators build a personal brand through her webinars",
      img: "/images/homepage/nikharika.png",
      bg_color: "#433968",
      desc_color: "#D5EFE7",
      sub_desc_color: "#CEE9DE",
    },
    {
      id: 2,
      title:
        "Conducting my 5 day masterclass has never been easier. Everything is just so seamless! Right from bookings to reminders to testimonials and payouts!",
      desc: "Aditi conducts masterclasses to guide people in their immigration journey",
      img: "/images/homepage/aditi.png",
      bg_color: "#23322D",
      desc_color: "#CEE9DE",
      sub_desc_color: "#D5EFE7",
    },
    {
      id: 3,
      title:
        "Topmate is a great platform for anyone who wants to launch a solopreneur business. For me, it is Calendly, Landing Page, Payment Collection Platform, all in one. For anyone, who is working towards serving more people - highly recommend!",
      desc: "Chengeer helps people win in their careers with long term mentorship packages",
      img: "/images/homepage/chengeer.png",
      bg_color: "#EEDABE",
      desc_color: "#1A4348",
      sub_desc_color: "#000000",
    },
    {
      id: 4,
      title:
        "I love Topmate! Scheduling 1:1 mentoring sessions is just so seamless! Now, I can also offer resume reviews over Priority DM, saving me so much time. Big fan of Topmate's WhatsApp integration!",
      desc: "Jessica helps people excel in their careers",
      img: "/images/homepage/jessica.png",
      bg_color: "#E0F4FE",
      desc_color: "#1A4348",
      sub_desc_color: "#000000",
    },
  ];

  const ReviewCard = ({ item }: any) => {
    return (
      <>
        <Row
          gutter={{ xs: 0, md: 24 }}
          justify="space-between"
          align="middle"
          className="active-card"
          style={{ height: "100%" }}
        >
          <Paragraph className={styles.SideReviewImage}></Paragraph>
          <Row
            gutter={{ xs: 0, md: 24 }}
            justify="space-between"
            className={styles.ReviewCard}
            style={{ background: item?.bg_color }}
          >
            <Col
              xs={{ span: 24, order: 2 }}
              md={{ span: 10, order: 1 }}
              className={styles.ImageContainer}
            >
              <TImage
                src={item?.img}
                alt="testimonial1"
                width={100}
                unoptimized
                className={styles.ReviewImage}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 14, order: 2 }}
              className={styles.ReviewContent}
            >
              <Paragraph
                className={styles.ReviewText}
                style={{ color: item?.desc_color }}
              >
                {item?.title}
              </Paragraph>
              <Paragraph
                className={styles.ReviewSubtext}
                style={{ color: item?.sub_desc_color }}
              >
                {item?.desc}
              </Paragraph>
            </Col>
          </Row>
          <Paragraph className={styles.SideReviewImage}></Paragraph>
        </Row>
        {/* <Paragraph className="other-cards">
          <TImage
            src={item?.Sideimg}
            alt="testimonial1"
            width={100}
            className={styles.ReviewImage}
          />
        </Paragraph> */}
      </>
    );
  };

  return (
    <Container type="fluid" className={styles.InfiHumanPossibilitiesSection}>
      <Col xs={24} md={24}>
        <Paragraph className={styles.Title}>Infinite possibilities</Paragraph>{" "}
        <Col xs={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }}>
          <Swiper
            centeredSlides
            loop
            slidesPerView={1}
            slidesPerGroup={1}
            spaceBetween={12}
            allowTouchMove={false}
            onInit={(ev): void => {
              console.log("ev", ev);
              setExpertImageSlider(ev);
            }}
            className="mySwiper infihuman-review-swiper"
          >
            {reviews.map((item, idx) => (
              <SwiperSlide key={idx} style={{ height: "auto" }}>
                <ReviewCard item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
          <Flex
            align="center"
            justify="center"
            className="review-swiper-arrows"
            gap={24}
          >
            <Paragraph
              className="left-right-arrow"
              onClick={(ev): void => {
                console.log("nected", ev);
                expertImageSlider.slidePrev();
              }}
            >
              <FiArrowLeft color="#fff" fontSize={20} />
            </Paragraph>
            <Paragraph
              className="left-right-arrow"
              onClick={(): void => {
                expertImageSlider.slideNext();
              }}
            >
              <FiArrowRight color="#fff" fontSize={20} />
            </Paragraph>
          </Flex>
        </Col>
      </Col>
    </Container>
  );
};

export default PossibilitiesSection;
