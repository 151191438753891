import { ArrowRightOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Flex, Modal, Rate, Row, Typography } from "antd";
import Container from "components/Common/Container";
import TImage from "components/Common/TImage";
import { signupRedirect } from "components/Layouts/Public/PublicLayout";
import { requestInviteEvent } from "components/PublicPages/Homepage/Homepage";
import { motion } from "framer-motion";
import Link from "next/link";
import Hero1 from "public/images/homepage/hero/img-landing-hero-1.webp";
import Hero2 from "public/images/homepage/hero/img-landing-hero-2.webp";
import Hero3 from "public/images/homepage/hero/img-landing-hero-3.webp";
import Hero4 from "public/images/homepage/hero/img-landing-hero-4.webp";
import Hero5 from "public/images/homepage/hero/img-landing-hero-5.webp";
import HeroBG1 from "public/images/homepage/hero/img-landing-hero-bg-1.svg";
import HeroBG2 from "public/images/homepage/hero/img-landing-hero-bg-2.svg";
import HeroBG3 from "public/images/homepage/hero/img-landing-hero-bg-3.svg";
import HeroBG4 from "public/images/homepage/hero/img-landing-hero-bg-4.svg";
import HeroBG5 from "public/images/homepage/hero/img-landing-hero-bg-5.svg";
import HeroMobileBG1 from "public/images/homepage/hero/img-landing-hero-mobile-1.svg";
import HeroMobileBG2 from "public/images/homepage/hero/img-landing-hero-mobile-2.svg";
import HeroMobileBG3 from "public/images/homepage/hero/img-landing-hero-mobile-3.svg";
import HeroMobileBG4 from "public/images/homepage/hero/img-landing-hero-mobile-4.svg";
import HeroMobileBG5 from "public/images/homepage/hero/img-landing-hero-mobile-5.svg";
import PowerUser1 from "public/images/homepage/power-users/user-1.png";
import PowerUser2 from "public/images/homepage/power-users/user-2.png";
import PowerUser3 from "public/images/homepage/power-users/user-3.png";
import PowerUser4 from "public/images/homepage/power-users/user-4.png";
import { useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import { Autoplay, EffectCreative } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Analytics } from "utils/Analytics/AnalyticsHelper";

const { Title, Paragraph } = Typography;

const PlayButton = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 50 50"
    fill="none"
  >
    <path
      d="M24.9986 44.8337C21.0764 44.8334 17.2423 43.6702 13.981 41.4911C10.7195 39.3118 8.17737 36.2143 6.67624 32.5902C5.1751 28.9662 4.78233 24.9783 5.54761 21.131C6.31288 17.2838 8.20182 13.7498 10.9756 10.976C13.7493 8.20231 17.2833 6.31337 21.1306 5.54809C24.9778 4.78282 28.9657 5.17559 32.5897 6.67672C36.2138 8.17786 39.3113 10.7199 41.4907 13.9815C43.6698 17.2428 44.8329 21.0769 44.8332 24.9991C44.8269 30.2576 42.7352 35.299 39.0168 39.0173C35.2985 42.7356 30.2572 44.8274 24.9986 44.8337Z"
      fill="#CB5A4B"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M20.8332 35.1871C19.7287 35.1827 18.6708 34.7418 17.8903 33.9604C17.1097 33.1791 16.6698 32.1207 16.6665 31.0163V18.983C16.6665 18.2516 16.8591 17.5331 17.2248 16.8997C17.5905 16.2663 18.1165 15.7403 18.7499 15.3746C19.3833 15.0089 20.1018 14.8164 20.8332 14.8164C21.5646 14.8164 22.2831 15.0089 22.9165 15.3746L33.3332 21.3913C33.9665 21.757 34.4925 22.283 34.8581 22.9164C35.2238 23.5498 35.4163 24.2683 35.4163 24.9996C35.4163 25.731 35.2238 26.4495 34.8581 27.0829C34.4925 27.7163 33.9665 28.2423 33.3332 28.608L22.9165 34.6246C22.2837 34.9922 21.565 35.1862 20.8332 35.1871Z"
      fill="white"
    />
  </svg>
);

const HomeHeroSection: React.FC = () => {
  const [showVideoModal, setVideoModalVisibility] = useState(false);
  const PowerUsers = [
    {
      id: 1,
      img: PowerUser1,
    },
    {
      id: 2,
      img: PowerUser2,
    },
    {
      id: 3,
      img: PowerUser3,
    },
    {
      id: 4,
      img: PowerUser4,
    },
  ];
  const HeroImages = [
    {
      id: 1,
      imgBG: HeroBG1,
      img: Hero1,
      mImg: HeroMobileBG1,
      alt: "Nidhi",
    },
    {
      id: 2,
      imgBG: HeroBG2,
      img: Hero2,
      mImg: HeroMobileBG2,
      alt: "Megan",
    },
    {
      id: 3,
      imgBG: HeroBG3,
      img: Hero3,
      mImg: HeroMobileBG3,
      alt: "Danny",
    },
    {
      id: 4,
      imgBG: HeroBG4,
      img: Hero4,
      mImg: HeroMobileBG4,
      alt: "Aishwarya",
    },
    {
      id: 5,
      imgBG: HeroBG5,
      img: Hero5,
      mImg: HeroMobileBG5,
      alt: "Joerg",
    },
  ];

  return (
    <Container type="fluid" className="homepage-main-back">
      <Container className="homepage-main-container">
        <Col xs={24} md={{ span: 24, offset: 0 }}>
          <Row justify="center">
            <Col xs={{ span: 22 }} md={{ span: 24 }}>
              <Row justify="center" align="stretch">
                <Col
                  xs={{ span: 24, offset: 0 }}
                  md={{ span: 11, offset: 0 }}
                  lg={{ span: 14, offset: 0 }}
                >
                  <Col xs={24} md={14}>
                    <Link
                      href={
                        "https://mentalhealth.topmate.io?utm_source=topmate&utm_medium=banner_strip&utm_campaign=mental_health"
                      }
                      onClick={() => {
                        Analytics.track("Banner Clicked", {
                          position: "landing",
                          type: "mental-health",
                          cta: "Find your mental health companion",
                        });
                      }}
                      target="_blank"
                    >
                      <TImage
                        src="/images/homepage/mental-health-banner.svg"
                        alt="mental-health"
                        width={390}
                        className={"mental-health-img"}
                      />
                    </Link>
                  </Col>
                  <motion.div className="hero-img-mobile-slider">
                    <Swiper
                      autoplay={{
                        delay: 4000,
                        pauseOnMouseEnter: true,
                      }}
                      loop
                      grabCursor={true}
                      effect={"creative"}
                      modules={[Autoplay, EffectCreative]}
                      creativeEffect={{
                        prev: {
                          shadow: true,
                          translate: [0, 0, -400],
                          scale: 0.1,
                        },
                        next: {
                          translate: ["100%", 0, 0],
                          scale: 1,
                        },
                      }}
                      fadeEffect={{ crossFade: true }}
                    >
                      {HeroImages?.map((hero) => (
                        <SwiperSlide key={hero?.id}>
                          <Paragraph>
                            <TImage
                              src={hero?.mImg?.src}
                              alt={hero?.alt}
                              width={530}
                              height={570}
                              loading="eager"
                              className="landing-hero-img"
                            />
                          </Paragraph>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, scale: 1, y: 50 }}
                    exit={{ opacity: 0, scale: 1, y: 50 }}
                    whileInView={{ opacity: 1, scale: 1, y: 0 }}
                    transition={{ delay: 0.1, duration: 0.6 }}
                  >
                    <Title level={1} className="landing-page-header">
                      Start your
                      <br />
                      <b>
                        side hustle
                        <br />
                        today
                      </b>
                    </Title>
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, scale: 1, y: 50 }}
                    exit={{ opacity: 0, scale: 1, y: 50 }}
                    whileInView={{ opacity: 1, scale: 1, y: 0 }}
                    transition={{ delay: 0.4, duration: 0.6 }}
                  >
                    <Title level={2} className="landing-page-subheader">
                      Turn your passion and knowledge into a thriving business.
                      <br />
                      Help your audience get ahead in life
                    </Title>
                  </motion.div>
                  <Row gutter={24} className="home-btns">
                    <Col xs={24} md={20}>
                      <motion.div
                        initial={{ opacity: 0, scale: 1, y: 50 }}
                        exit={{ opacity: 0, scale: 1, y: 50 }}
                        whileInView={{ opacity: 1, scale: 1, y: 0 }}
                        transition={{ delay: 0.6, duration: 0.5 }}
                      >
                        <Flex className="landing-cta" align="center" gap={12}>
                          <Link
                            href={signupRedirect()}
                            style={{
                              width: "50%",
                            }}
                          >
                            <Button
                              type="default"
                              size="large"
                              className="btn-dark"
                              block
                              onClick={(): void =>
                                requestInviteEvent("below_time_audience")
                              }
                            >
                              Start My Page
                              <ArrowRightOutlined />
                            </Button>
                          </Link>
                          <Button
                            type="default"
                            size="large"
                            className="btn-dark inverse"
                            onClick={(): void => {
                              setVideoModalVisibility(true);
                            }}
                            block
                            icon={<PlayButton />}
                          >
                            Watch The Film
                            {/* <ArrowRightOutlined /> */}
                          </Button>
                        </Flex>
                      </motion.div>
                    </Col>
                  </Row>
                  <motion.div
                    initial={{ opacity: 0, scale: 1, y: 50 }}
                    exit={{ opacity: 0, scale: 1, y: 50 }}
                    whileInView={{ opacity: 1, scale: 1, y: 0 }}
                    transition={{ delay: 0.9, duration: 0.5 }}
                  >
                    <Paragraph className="landing-avatar">
                      <Paragraph className="avatar-profile">
                        <Avatar.Group>
                          {PowerUsers.map((user) => (
                            <Avatar
                              gap={6}
                              src={
                                <TImage
                                  src={user?.img?.src}
                                  width={50}
                                  height={50}
                                  alt={`${user?.id}`}
                                  loading="lazy"
                                />
                              }
                              style={{ backgroundColor: "#fff", border: 0 }}
                              key={user?.id}
                              shape="circle"
                              size={50}
                            />
                          ))}
                        </Avatar.Group>
                      </Paragraph>
                      <Paragraph style={{ fontSize: "18px" }}>
                        <Paragraph style={{ width: 200 }}>
                          <Rate
                            disabled
                            defaultValue={5}
                            character={
                              <AiFillStar
                                color="#F7941F"
                                className="star-ratings"
                              />
                            }
                          />
                        </Paragraph>
                        5/5 by 1000+ creators
                      </Paragraph>
                    </Paragraph>
                  </motion.div>
                </Col>
                <Col
                  xs={{ span: 0, offset: 0 }}
                  md={{ span: 10, offset: 2 }}
                  lg={{ span: 10, offset: 0 }}
                  className="text-right"
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 1, x: 100 }}
                    exit={{ opacity: 0, scale: 1, x: 100 }}
                    whileInView={{ opacity: 1, scale: 1, x: 0 }}
                    transition={{ delay: 1, duration: 0.6 }}
                  >
                    <Paragraph className="home-video">
                      <Swiper
                        autoplay={{
                          delay: 4000,
                          pauseOnMouseEnter: true,
                        }}
                        loop
                        grabCursor={true}
                        effect={"creative"}
                        modules={[Autoplay, EffectCreative]}
                        creativeEffect={{
                          prev: {
                            shadow: true,
                            translate: [0, 0, -400],
                            scale: 0.1,
                          },
                          next: {
                            translate: ["100%", 0, 0],
                            scale: 1,
                          },
                        }}
                        fadeEffect={{ crossFade: true }}
                      >
                        {HeroImages?.map((hero) => (
                          <SwiperSlide key={hero?.id}>
                            <Paragraph
                              style={{
                                position: "relative",
                              }}
                            >
                              <TImage
                                quality={100}
                                src={hero?.imgBG?.src}
                                alt={hero?.alt}
                                width={530}
                                height={570}
                                loading="eager"
                                className="landing-hero-img-bg"
                              />
                              <TImage
                                src={hero?.img?.src}
                                alt={hero?.alt}
                                width={530}
                                height={570}
                                loading="eager"
                                className="landing-hero-img"
                              />
                            </Paragraph>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </Paragraph>
                  </motion.div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Container>
      <Modal
        open={showVideoModal}
        onCancel={(): void => {
          Analytics.track("Public Popup Closed", {
            screen: "landing",
            type: "demo_video_1",
            title: "Watch video",
          });
          setVideoModalVisibility(false);
        }}
        footer={null}
        centered
        width={870}
        destroyOnClose
        styles={{
          body: {
            padding: 0,
          },
        }}
        className="video-modal"
        closeIcon={<IoCloseOutline fontSize={43} color="#fff" />}
      >
        <iframe
          id="ytplayer"
          width="840"
          height="472"
          allow="autoplay"
          className="youtube-player"
          src="https://www.youtube.com/embed/ISIvkX0GIvg?autoplay=1&enablejsapi=1"
          frameBorder={0}
        />
      </Modal>
    </Container>
  );
};

export default HomeHeroSection;
